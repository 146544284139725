import React, { Component } from 'react'
import { Button, Grid } from '@material-ui/core';
import StopIcon from '@material-ui/icons/Stop';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

import './Clock.css';

class Clock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: null,
            diff: null,
            sospesi: 0,
            started: null,
            lap: []
        }
    }

    addZero = t => {
        return t < 10 ? '0' + t : t
    }

    tick = () => {
        this.setState({
            //diff: new Date(+new Date() - this.state.data),
            diff: +new Date() - this.state.data,
        })
    }

    start = () => {
        if (this.state.started) {
            // prevent multi clicks on start
            return;
        }
        
        this.setState({
            data: +new Date() - this.state.sospesi,
            sospesi: 0,
            started: true
        })
 
        this.interval = setInterval(this.tick, 1)
    }

    stop = () => {
        this.setState({
            sospesi: +this.state.diff,
            started: false
        })
        clearInterval(this.interval);
    }

    reset = () => {
        if (this.state.started) {
            // prevent click reset on running
            return;
        }
        this.setState({
            data: null,
            diff: null,
            sospesi: 0,
            started: null,
            lap: []
        })
    }

    lap = () => {
        if (!this.state.started) {
            // prevent click reset on running
            return;
        }
        this.setState(state => {
            const lap = this.state.lap.concat(this.state.diff);
            return {
                lap
            };
        })
    }


  render() {

    var diff = this.state.diff;
    var centisecondi = diff ? Math.round(new Date(this.state.diff).getMilliseconds()/10) : 0;
    var secondi = diff ? new Date(this.state.diff).getSeconds() : 0;
    var minuti = diff ? new Date(this.state.diff).getMinutes() : 0;
    var ore = diff ? new Date(this.state.diff).getHours()-1 : 0;

    if (centisecondi === 100) centisecondi = 0;

    return (
      <>
      
        <Grid item xs={12}>
            <div className="clock">
                <h1>{this.addZero(ore)}:{this.addZero(minuti)}:{this.addZero(secondi)}:{this.addZero(centisecondi)}</h1>
            </div>
        </Grid>

        {/*
        Data: {this.state.data}
        <br></br>Diff: {this.state.diff}
        <br></br>Sospesi: {this.state.sospesi}
        */}
        <Grid item xs={12} className="buttons">
            <Button variant="contained" color="primary" className="start" onClick={ this.start }><PlayArrowIcon/>START</Button>
            <Button variant="contained" color="secondary" className="stop" onClick={ this.stop }><StopIcon/>STOP</Button>
            <Button variant="contained"  className="lap" onClick={ this.lap }><FormatListNumberedIcon/>LAP</Button>
            <Button variant="outlined" color="secondary" className="reset" onClick={ this.reset }><RotateLeftIcon/>RESET</Button>
        </Grid>
        
            <Grid item xs={12} className="buttons">
                <h3>Lista tempi:</h3>
                <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={6} className="buttons">
                <ul className="laps">

                {this.state.lap.map(item => (
                    <li key={item}>{new Date(item).getHours()-1}:{this.addZero(new Date(item).getMinutes())}:{this.addZero(new Date(item).getSeconds())}:{Math.round(new Date(item).getMilliseconds()/10)}</li>
                ))}
                </ul>
                </Grid>
            </Grid>
        </Grid>
      </>
    )
  }
}

export default Clock