import React from 'react';

import Clock from './Clock';

import { Container } from '@material-ui/core';

function App() {
  return (
    <div>
      <header>
      </header>
      <Container fixed maxWidth="sm">
        <Clock />
      </Container>
    </div>
  );
}

export default App;
